

























import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    serviceId: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(props, { root }) {
    const state = reactive({
      loading: false,
      loaded: false,
      error: false as boolean | number,
      items: [],
      options: {
        sortBy: ["createdAt"],
        sortDesc: [false],
      },
      headers: [
        {
          value: "createdAt",
          sortable: false,
          text: root.$tc("layout.misc.registrationDate"),
        },
        {
          value: "lastName",
          sortable: false,
          text: root.$tc("layout.misc.lastName"),
          align: "right",
        },
        {
          value: "firstName",
          sortable: false,
          text: root.$tc("layout.misc.firstName"),
          align: "right",
        },
        {
          value: "actions",
          sortable: false,
          align: "right",
        },
      ],
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const { sortBy, sortDesc } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`additional-service/${props.serviceId}/reserveList`, {
          params: { sortBy, sortDesc },
        })
        .then(({ data }) => {
          state.items = data.filter((item: any) => item.participant);
          console.log(state.items);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const moveToMainList = (serviceId: string, participantId: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .put(
          `additional-service/${serviceId}/reserveList/${participantId}`,
          {},
          {
            headers: {
              event: root.$route.params.id,
            },
          }
        )
        .then(() => {
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("layout.misc.movedToMainList"),
          });
          fetchData();
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };
    return { state, moveToMainList };
  },
});
